import React, { useEffect, useState, useMemo } from "react";
import { render } from "react-dom";
import { useAuth0 } from "@auth0/auth0-react";
import mapboxgl from "mapbox-gl";
import {
  Map,
  Marker,
  GeolocateControl,
  FullscreenControl,
  NavigationControl,
  ScaleControl,
  Source,
  Layer,
  Popup,
} from "react-map-gl";
import { fetchData, fetchEdgedevices } from "./myApiHelpers.js";
import "./Explore.css";

// eslint-disable-next-line import/no-webpack-loader-syntax
import MapboxWorker from "worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker";
mapboxgl.workerClass = MapboxWorker;

const TOKEN = process.env.REACT_APP_MAPBOX_TOKEN;

export default function App() {
  const { getAccessTokenSilently } = useAuth0();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [edgeData, setEdgeData] = useState([]);
  const [error, setError] = useState(null);
  const [filterLTE, setFilterLTE] = useState(false);
  const [edge_id, setEdgeId] = useState(""); // Add default value
  const [initialCoordinates, setInitialCoordinates] = useState({
    lat: 0.0,
    lon: 0.0,
  }); // Default values
  const [initialCoordinatesSet, setInitialCoordinatesSet] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const [showNoLocationPopup, setShowNoLocationPopup] = useState(false);
  const [hoveredTrackInfo, setHoveredTrackInfo] = useState(null);

  // First useEffect: Fetch edge devices
  useEffect(() => {
    const fetchEdgeDevices = async () => {
      try {
        setIsLoading(true);
        const edgeDevices = await fetchEdgedevices(getAccessTokenSilently);
        setEdgeData(edgeDevices);
        if (!edge_id && edgeDevices.length > 0) {
          setEdgeId(edgeDevices[0].GSI1SK);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchEdgeDevices();
  }, [getAccessTokenSilently, edge_id]);

  // Second useEffect: Fetch data and handle LTE filter
  useEffect(() => {
    const fetchDataAndApplyFilter = async () => {
      try {
        if (edge_id) {
          setIsLoading(true);
          let actualData = await fetchData(getAccessTokenSilently, edge_id);
          if (filterLTE) {
            actualData = actualData.filter((item) => item.gnssSource !== "LTE");
          }
          setData(actualData);

          // Determine what to show based on data
          if (actualData.length > 0) {
            const firstItem = actualData[0];
            if (firstItem.lat && firstItem.lon) {
              setInitialCoordinates({
                lat: parseFloat(actualData[0].lat),
                lon: parseFloat(actualData[0].lon),
              });
              setInitialCoordinatesSet(true);
              setShowMap(true);
              setShowNoLocationPopup(false);
            } else {
              setShowNoLocationPopup(true);
              setShowMap(false);
            }
          }
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchDataAndApplyFilter();
  }, [getAccessTokenSilently, edge_id, filterLTE]);

  const handleEdgeIdChange = (e) => {
    const selectedEdgeId = e.target.value;
    setEdgeId(selectedEdgeId);
    setInitialCoordinatesSet(false); // Reset the flag to indicate that initial coordinates need to be set
    setShowNoLocationPopup(false);
    setShowMap(false); // Hide the map until new location data is fetched
  };

  const handlePopupDismiss = () => {
    setShowNoLocationPopup(false);
    setShowMap(true); // Show the map after the pop-up is dismissed
  };

  const handleTrackClick = (event) => {
    const features = event.target.queryRenderedFeatures(event.point, {
      layers: ["track"], // Specify the ID of the track layer
    });

    if (features.length > 0) {
      // Track layer clicked
      const clickedFeature = features[0];
      const coordinates = event.lngLat;

      const { time, temperature } = clickedFeature.properties;
      const trackIndex = clickedFeature.properties.index; // Assuming 'index' is the property representing the track index

      const popup = new mapboxgl.Popup()
        .setLngLat(coordinates)
        .setHTML(renderTrackInfoPopup(trackIndex, time, temperature))
        .addTo(event.target);
    }
  };

  const handleTrackHover = (event) => {
    const features = event.target.queryRenderedFeatures(event.point, {
      layers: ["track"], // Specify the ID of the track layer
    });

    if (features.length > 0) {
      // Track layer hovered
      const hoveredFeature = features[0];
      const coordinates = event.lngLat;

      new mapboxgl.Popup()
        .setLngLat(coordinates)
        .setHTML(
          renderTrackInfoPopup(null, hoveredFeature.properties.time, null)
        )
        .addTo(event.target);
    }
  };

  const handleTrackLeave = (event) => {
    event.target.getCanvas().style.cursor = "";
    event.target.closePopup();
  };

  const track = useMemo(() => {
    const filteredData = data.filter(
      (item) => parseFloat(item.lat) !== 0 && parseFloat(item.lon) !== 0
    );

    return {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          geometry: {
            type: "LineString",
            coordinates: filteredData.map((item) => [
              parseFloat(item.lon),
              parseFloat(item.lat),
            ]),
          },
        },
      ],
    };
  }, [data]);

  const renderTrackInfoPopup = (trackIndex, time, temperature) => {
    return (
      <div>
        <h3>Track Info</h3>
        {trackIndex && <p>Track Index: {trackIndex}</p>}
        {time && <p>Time: {time}</p>}
        {temperature && <p>Temperature: {temperature}</p>}
      </div>
    );
  };

  const toggleLTEFilter = () => {
    setFilterLTE((prev) => !prev);
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!initialCoordinatesSet) {
    return <div>Setting initial coordinates...</div>;
  }

  if (!initialCoordinatesSet || showNoLocationPopup) {
    return (
      <div>
        <div className="popup-box">
          <div className="popup-box-content">
            <h3>Device has no recent location</h3>
            <p>Make sure it is turned on and clear view to the sky.</p>
            <button onClick={handlePopupDismiss}>OK</button>
          </div>
        </div>
      </div>
    );
  }

  const initialZoom =
    initialCoordinates.lat === 0.0 && initialCoordinates.lon === 0.0 ? 2 : 14;

  return (
    <div style={{ position: "relative", height: "100vh" }}>
      <select
        value={edge_id}
        onChange={handleEdgeIdChange}
        style={{ position: "absolute", zIndex: 1, top: "200px", left: "10px" }}
      >
        {edgeData.map((edge) => (
          <option key={edge.GSI1SK} value={edge.GSI1SK}>
            {edge.GSI1SK}
          </option>
        ))}
      </select>
      <div style={{ position: "relative", height: "100vh" }}>
        {/* Button to toggle LTE filter */}
        <button
          onClick={toggleLTEFilter}
          style={{
            position: "absolute",
            zIndex: 1,
            top: "250px",
            left: "10px",
          }}
        >
          {filterLTE ? "LTE and GPS" : "GPS Only"}
        </button>

        {/* Existing JSX for rendering the map and other components */}
        {/* ... */}
      </div>
      <Map
        style={{ position: "absolute", top: 0, bottom: 0, width: "100%" }}
        initialViewState={{
          latitude: initialCoordinates.lat,
          longitude: initialCoordinates.lon,
          zoom: initialZoom,
          bearing: 0,
          pitch: 0,
        }}
        mapStyle="mapbox://styles/mapbox/satellite-streets-v12"
        mapboxAccessToken={TOKEN}
        onClick={handleTrackClick}
      >
        <Source id="track" type="geojson" data={track}>
          {track.features && track.features.length > 0 ? (
            <>
              <Marker
                longitude={track.features[0].geometry.coordinates[0][0]}
                latitude={track.features[0].geometry.coordinates[0][1]}
                color="red"
              />
              <Marker
                longitude={
                  track.features[0].geometry.coordinates.slice(-1)[0][0]
                }
                latitude={
                  track.features[0].geometry.coordinates.slice(-1)[0][1]
                }
                color="red"
              />
            </>
          ) : (
            showNoLocationPopup && (
              <div className="popup-box">
                <div className="popup-box-content">
                  <h3>Device has no recent location</h3>
                  <p>
                    Make sure it is turned on and has a clear view of the sky.
                  </p>
                  <button onClick={handlePopupDismiss}>OK</button>
                </div>
              </div>
            )
          )}
          <Layer
            id="track"
            type="line"
            layout={{
              "line-join": "round",
              "line-cap": "round",
            }}
            paint={{
              "line-color": "#FF0000",
              "line-width": 4,
            }}
            interactive={true}
            onMouseEnter={handleTrackHover}
            onMouseLeave={handleTrackLeave}
          />
        </Source>
        {hoveredTrackInfo && (
          <Popup
            longitude={hoveredTrackInfo.geometry.coordinates[0]}
            latitude={hoveredTrackInfo.geometry.coordinates[1]}
            onClose={() => setHoveredTrackInfo(null)}
            anchor="top"
            className="my-popup"
          >
            {/* Render the track information inside the popup */}
            {renderTrackInfoPopup(
              hoveredTrackInfo.properties.index,
              hoveredTrackInfo.properties.time,
              hoveredTrackInfo.properties.temperature
            )}
          </Popup>
        )}
        <GeolocateControl position="top-left" />
        <FullscreenControl position="top-left" />
        <NavigationControl position="top-left" />
        <ScaleControl position="bottom-right" />
      </Map>
    </div>
  );
}

export function renderToDom(container) {
  render(<App />, container);
}
