import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { listEdgeDevices } from "./myApiHelpers";
import "./EdgeDevices.css";
import EdgeDevicesTable from "./EdgeDevicesTable";
import EdgeDevicesForm from "./EdgeDevicesForm";
import { accessToken } from "mapbox-gl";

const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN;

const EdgeDevices = () => {
  const { user, getAccessTokenSilently } = useAuth0();
  const [edgeId] = useState("108");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const getData = async () => {
    try {
      setLoading(true);
      setError(null);
      const accessToken = await getAccessTokenSilently({
        audience: `https://${AUTH0_DOMAIN}/api/v2/`,
        scope: "read:current_user",
      });
      const response = await listEdgeDevices(accessToken, edgeId, 300);
      setData(response);
    } catch (e) {
      setError("An error occurred while fetching the data.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, [getAccessTokenSilently, user?.sub, edgeId, accessToken]);

  return (
    <div className="App">
      <div>
        <h1>Edge Inventory</h1>
        {loading ? (
          <div>Loading...</div>
        ) : error ? (
          <div>{error}</div>
        ) : (
          <>
            <EdgeDevicesTable data={data} />
            <EdgeDevicesForm getData={getData} />
          </>
        )}
      </div>
    </div>
  );
};

export default EdgeDevices;
