import React, { useState } from "react";
import EdgeDevicesRow from "./EdgeDevicesRow";

const EdgeDevicesTable = ({ data }) => {
  const [modalIsOpenArr, setModalIsOpenArr] = useState([]);
  const openModalForRow = (index) => {
    const updatedModalIsOpenArr = [...modalIsOpenArr];
    updatedModalIsOpenArr[index] = true;
    setModalIsOpenArr(updatedModalIsOpenArr);
  };

  const closeModalForRow = (index) => {
    const updatedModalIsOpenArr = [...modalIsOpenArr];
    updatedModalIsOpenArr[index] = false;
    setModalIsOpenArr(updatedModalIsOpenArr);
  };

  return (
    <div className="table-container">
      <table>
        <thead>
          <tr>
            <th>Edge ID</th>
            <th>Name</th>
            <th>Settings</th>
            <th>Delete</th>
          </tr>
        </thead>
        {data.map((item, index) => (
          <EdgeDevicesRow
            key={index}
            item={item}
            index={index}
            modalIsOpenArr={modalIsOpenArr}
            openModalForRow={openModalForRow}
            closeModalForRow={closeModalForRow}
          />
        ))}
      </table>
    </div>
  );
};

export default EdgeDevicesTable;
