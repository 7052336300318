import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import "./EdgeDevices.css";

// Assuming readEdgeConfig is correctly imported from your API helper functions
import { readEdgeConfig } from "./myApiHelpers";
import EdgeSettingsForm from "./EdgeSettingsForm";

const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN;

function EdgeSettings({ item }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { getAccessTokenSilently } = useAuth0();
  const [isFormVisible, setFormVisible] = useState(false);

  console.log(item);

  const toggleForm = () => {
    setFormVisible(!isFormVisible);
  };

  const getData = async () => {
    try {
      const accessToken = await getAccessTokenSilently({
        audience: `https://${AUTH0_DOMAIN}/api/v2/`,
        scope: "read:current_user",
      });
      const response = await readEdgeConfig(accessToken, item.GSI1SK);
      setData(response);
    } catch (e) {
      setError("An error occurred while fetching the data.");
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
    // The dependencies array is updated to include functions and variables that are used within the effect.
  }, [getAccessTokenSilently, item.GSI1SK]);

  if (loading) {
    return (
      <div className="settings-header">
        <h1>Edge Device Settings</h1>
        <p>Loading settings...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="settings-header">
        <h1>Edge Device Settings</h1>
        <p>{error}</p>
      </div>
    );
  }

  return (
    <div className="settings">
      <h1>Edge Device Settings</h1>

      <div className="settings-table-container">
        <table>
          <tbody>
            <tr className="settings-section-header">
              <td colSpan="2">
                <strong>Metadata:</strong>
              </td>
              <td colSpan="2">
                <strong>Config:</strong>
              </td>
            </tr>
            <tr>
              <td>Name:</td>
              <td>{item.edge_metadata.edge_name}</td>
              <td>Edge Mode:</td>
              <td title="Passive Mode enables tracker to only send postion based on shock or movement.">
                {data.act ? "Active" : "Passive"}
              </td>
            </tr>
            <tr>
              <td>Description:</td>
              <td>{item.edge_metadata.edge_description}</td>
              <td>Config Loct:</td>
              <td>{data.loct}</td>
            </tr>
            <tr>
              <td>Creation Date:</td>
              <td>{item.edge_metadata.creation_time}</td>
              <td>Config Actwt:</td>
              <td>{data.actwt}</td>
            </tr>
            <tr>
              <td>Edge Party ID:</td>
              <td>{item.edge_metadata.edge_party_id}</td>
              <td>Config Mvres:</td>
              <td>{data.mvres}</td>
            </tr>
            <tr>
              <td>Edge Status:</td>
              <td>{item.edge_metadata.status}</td>
              <td>Config Mvt:</td>
              <td>{data.mvt}</td>
            </tr>
            <tr>
              <td>Edge Type:</td>
              <td>{item.edge_metadata.edge_type}</td>
              <td>Config Accath:</td>
              <td>{data.accath}</td>
            </tr>
            <tr>
              <td>Created By:</td>
              <td>{item.edge_metadata.creation_uid}</td>
              <td>Config Accith:</td>
              <td>{data.accith}</td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td>Config Accito:</td>
              <td>{data.accito}</td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td>Config Nod:</td>
              <td>
                {data.nod && data.nod.length > 0
                  ? data.nod.map((item, index) => <div key={index}>{item}</div>)
                  : "Not Configured"}
              </td>
            </tr>
          </tbody>
        </table>
        <div className="form-popup">
          <button type="button" onClick={toggleForm}>
            Change Settings
          </button>
        </div>
        {/* The form will be visible based on the `isFormVisible` state */}
        {isFormVisible && (
          <EdgeSettingsForm
            edgeId={item.GSI1SK}
            config={data} // passing the current configuration data to the form
            onClose={toggleForm} // passing the function to handle form closure
            edgeDescription={item.edge_metadata.edge_description}
            edgeName={item.edge_metadata.edge_name}
          />
        )}

        <div className="settings-content">
          {data ? <></> : <p>No data to display.</p>}
        </div>
      </div>
    </div>
  );
}

export default EdgeSettings;
