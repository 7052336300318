const API_URL = process.env.REACT_APP_API_URL;

export const listEdgeDevices = async (sessionToken) => {
  const response = await fetch(`${API_URL}/v2/edge`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionToken,
    },
  });
  return await response.json();
};

export const updateEdgeDevice = async (sessionToken, payload) => {
  const response = await fetch(`${API_URL}/v2/edge`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionToken,
    },
    body: JSON.stringify(payload), // Add the payload to the request body
  });
  return await response.json();
};

export const createEdgeDevice = async (sessionToken, payload) => {
  const response = await fetch(`${API_URL}/v2/edge`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionToken,
    },
    body: JSON.stringify(payload), // Add the payload to the request body
  });
  return await response.json();
};

export const deleteEdgeDevice = async (sessionToken, payload) => {
  const response = await fetch(`${API_URL}/v2/edge`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionToken,
    },
    body: JSON.stringify(payload), // Add the payload to the request body
  });
  return await response.json();
};

export const readEdgeConfig = async (sessionToken, edgeId) => {
  const response = await fetch(`${API_URL}/v2/edge/config/readEdgeConfig`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionToken,
    },
    body: JSON.stringify({ edge_id: edgeId }), // Add the payload to the request body
  });
  return await response.json();
};

const formatConfigTypes = (config) => {
  const formattedConfig = {};

  for (const [key, value] of Object.entries(config)) {
    switch (key) {
      case "act":
        // Convert string to boolean
        formattedConfig[key] = value === "true" || value === true;
        break;
      case "nod":
        // Assuming that the array contains primitive types which don't need formatting
        // If 'nod' array items are objects, additional logic would be needed to format them
        formattedConfig[key] = Array.isArray(value) ? value : JSON.parse(value);
        break;
      case "loct":
      case "actwt":
      case "mvres":
      case "mvt":
      case "accath":
      case "accith":
      case "accito":
        // Convert string to integer
        formattedConfig[key] = parseInt(value, 10);
        break;
      default:
        // If the schema is extended in the future, handle other cases
        formattedConfig[key] = value;
    }
  }

  return formattedConfig;
};

export const writeEdgeConfig = async (sessionToken, edgeId, newConfig) => {
  // Format config values to their proper types
  const formattedConfig = formatConfigTypes(newConfig);

  const response = await fetch(`${API_URL}/v2/edge/config/writeEdgeConfig`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionToken,
    },
    body: JSON.stringify({ edge_id: edgeId, new_cfg: formattedConfig }), // Add the formatted payload to the request body
  });

  return await response.json();
};
