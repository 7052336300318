import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

const API_URL = process.env.REACT_APP_API_URL;
const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN;

const Home = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state
  const { user, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const getUserMetadata = async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          audience: `https://${AUTH0_DOMAIN}/api/v2/`,
          scope: "read:current_user",
        });

        const userDetailsByIdUrl = `${API_URL}/v2/edge`;

        await fetch(userDetailsByIdUrl, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
          .then((response) => response.json())
          .then((actualData) => {
            setData(actualData);
            setLoading(false); // Update loading state when data is received
          })
          .catch((err) => {
            console.log(err.message);
            setLoading(false); // Update loading state on error
          });
      } catch (e) {
        console.log(e.message);
        setLoading(false); // Update loading state on error
      }
    };

    getUserMetadata();
  }, [getAccessTokenSilently, user?.sub]);
  console.log(data);
  return (
    <div className="App">
      <h3>Dashboard</h3>
      {loading ? ( // Render loading message if the data is still loading
        <p>Loading...</p>
      ) : (
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Desc</th>
              <th>Loc</th>
              <th>Bat</th>
              <th>Time</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                <td>{item.GSI1SK}</td>
                <td>{item.edge_metadata.edge_name}</td>
                <td>{item.edge_metadata.edge_description}</td>
                <td>{item.location_metadata.label}</td>
                <td>
                  {item.last_payload.batteryStatus
                    ? `${item.last_payload.batteryStatus.voltage}%`
                    : "0%"}
                </td>

                <td>
                  {new Date(item.last_payload.edgeTimestamp).toLocaleString(
                    navigator.language,
                    {
                      year: "numeric",
                      month: "numeric",
                      day: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                      second: "numeric",
                      hour12: false,
                    }
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default Home;
