import React, { useState } from "react";
import { createEdgeDevice } from "./myApiHelpers";
import "./EdgeDevices.css";

const EdgeDevicesForm = ({ getData }) => {
  const [newName, setNewName] = useState("");
  const [formResponse, setFormResponse] = useState("");

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await createEdgeDevice(newName);
      getData();
      setFormResponse(response.message);
      setTimeout(() => {
        setFormResponse("");
      }, 3000);
    } catch (error) {
      setFormResponse("Failed to add an asset. Please try again.");
    }
  };

  return (
    <div className="form-popup">
      <form onSubmit={handleFormSubmit}>
        <p>Enter the new asset ID</p>
        <input
          type="text"
          value={newName}
          onChange={(e) => setNewName(e.target.value)}
          maxLength={10}
        />
        <button type="submit">Add Asset</button>
        {formResponse && <p className="form-response">{formResponse}</p>}
      </form>
    </div>
  );
};

export default EdgeDevicesForm;
