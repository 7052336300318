import React from "react";
import Modal from "react-modal";
import { MapComponent } from "./map.js";

const MetricsTable = ({
  data,
  modalIsOpenArr,
  openModalForRow,
  closeModalForRow,
}) => {
  return (
    <div className="table-container">
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Time</th>
            <th>Edge</th>
            <th>Temp (C)</th>
            <th>Humi (%)</th>
            <th>Elev (m)</th>
            <th>Lat</th>
            <th>Lon</th>
            <th>Src</th>
            <th>Map</th>
            {/* <th>Sea Level Pressure (hPa)</th> */}
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => {
            // Get the modal state for the current row
            const isModalOpen = modalIsOpenArr[index];

            return (
              <tr key={index}>
                <td>{item.GSI1PK}</td>
                <td>
                  {new Date(item.GSI1SK).toLocaleString(navigator.language, {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric",
                    hour12: false,
                  })}
                </td>
                <td>
                  {new Date(item.edgeTimestamp).toLocaleString(
                    navigator.language,
                    {
                      year: "numeric",
                      month: "numeric",
                      day: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                      second: "numeric",
                      hour12: false,
                    }
                  )}
                </td>
                <td>{item.temperature}</td>
                <td>{item.humidity}</td>
                <td>{item.elevation}</td>
                <td>
                  {Number(item.lat) === 0 ? "0" : Number(item.lat).toFixed(4)}
                </td>
                <td>
                  {Number(item.lon) === 0 ? "0" : Number(item.lon).toFixed(4)}
                </td>
                <td>{item.gnssSource}</td>
                <td>
                  <button onClick={() => openModalForRow(index)}>Map</button>
                  <Modal
                    isOpen={isModalOpen}
                    contentLabel="Map Modal"
                    overlayClassName="modal-overlay" // Assuming the library accepts this prop for styling the overlay
                    className="modal-content" // Assuming the library accepts this prop for styling the content
                  >
                    <div
                      className="close-icon"
                      onClick={() => closeModalForRow(index)}
                    >
                      &times;
                    </div>
                    <MapComponent
                      latitude={parseFloat(item.lat)}
                      longitude={parseFloat(item.lon)}
                    />
                  </Modal>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default MetricsTable;
