// In myApiHelpers.js
const API_URL = process.env.REACT_APP_API_URL;

export const fetchItems = async (sessionToken, edgeId, days) => {
  const response = await fetch(
    `${API_URL}/v2/timeseries/foo?edge_id=${edgeId}&days_ago=${days}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionToken,
      },
    }
  );
  return await response.json();
};

export const fetchItemsRange = async (
  sessionToken,
  edgeId,
  startDate,
  endDate
) => {
  const response = await fetch(
    `${API_URL}/v2/timeseries/foo?edge_id=${edgeId}&start_date=${startDate}&end_date=${endDate}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionToken,
      },
    }
  );
  return await response.json();
};

export const fetchMessageRange = async (
  sessionToken,
  edgeId,
  startDate,
  endDate
) => {
  const response = await fetch(`${API_URL}/v2/message/listMessages`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionToken,
    },
    body: JSON.stringify({
      edge_id: edgeId,
      start_date: startDate,
      end_date: endDate,
    }),
  });
  return await response.json();
};

export const listEdgeDevices = async (sessionToken) => {
  const response = await fetch(`${API_URL}/v2/edge`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionToken,
    },
  });
  return await response.json();
};

export const timeOnLocation = async (sessionToken, payload) => {
  const response = await fetch(`${API_URL}/v2/analytics/geo/timeOnLocation`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionToken,
    },
    body: JSON.stringify(payload), // Add the payload to the request body
  });
  return await response.json();
};
