import React from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import Sidenav from "./Components/Sidenav";
import Explore from "./Pages/Explore/Explore";
import Home from "./Pages/Home";
import Settings from "./Pages/Settings";
import EdgeDevices from "./Pages/EdgeDevices/EdgeDevices";
import Metrics from "./Pages/Metrics/Metrics";
import User from "./Pages/User";
import { useAuth0 } from "@auth0/auth0-react";
import Callback from "./Components/Callback";
import Modal from "react-modal";

Modal.setAppElement("#root");

function App() {
  const { isLoading, isAuthenticated, error, loginWithRedirect } = useAuth0();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (!isAuthenticated) {
    loginWithRedirect();
    return <div>Redirecting to login...</div>;
  }

  return (
    <div className="app-container">
      <Sidenav />
      <main className="content-wrapper">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/explore" element={<Explore />} />
          <Route path="/EdgeDevices" element={<EdgeDevices />} />
          <Route path="/Metrics" element={<Metrics />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/userinfo" element={<User />} />
          <Route path="/callback" element={<Callback />} />
        </Routes>
      </main>
    </div>
  );
}

export default App;
