import React from "react";

const EdgeDeviceSelect = ({ edgeId, setEdgeId, edgeData }) => {
  const handleEdgeIdChange = (e) => {
    const selectedEdgeId = e.target.value;
    setEdgeId(selectedEdgeId);
  };

  return (
    <div>
      <div>
        <label htmlFor="edgeId" style={{ color: "white" }}>
          Select Edge ID:{" "}
        </label>
        <select
          value={edgeId}
          onChange={handleEdgeIdChange}
          style={{
            backgroundColor: "#333", // Dark background color
            color: "white", // Text color
            padding: "5px",
            borderRadius: "4px",
            border: "none",
            outline: "none",
          }}
        >
          {edgeData.map((edge) => (
            <option
              key={edge.GSI1SK}
              value={edge.GSI1SK}
              style={{ backgroundColor: "#333", color: "white" }}
            >
              {edge.GSI1SK}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default EdgeDeviceSelect;
