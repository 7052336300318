import React, { useState } from "react";
import Modal from "react-modal";
import EdgeSettings from "./EdgeSettings";
import { useAuth0 } from "@auth0/auth0-react";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  listEdgeDevices,
  updateEdgeDevice,
  deleteEdgeDevice,
} from "./myApiHelpers";
import "./EdgeDevices";

const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN;

const EdgeDevicesRow = ({
  item,
  index,
  modalIsOpenArr,
  openModalForRow,
  closeModalForRow,
}) => {
  const [editingGSI1SK, setEditingGSI1SK] = useState(null);
  const [newName, setNewName] = useState("");

  const { user, getAccessTokenSilently } = useAuth0();

  const handleDeleteClick = () => {
    deleteEdgeDevice(item.GSI1SK);
  };

  const [edgeId] = useState("108");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const getData = async () => {
    try {
      setLoading(true);
      setError(null);
      const accessToken = await getAccessTokenSilently({
        audience: `https://${AUTH0_DOMAIN}/api/v2/`,
        scope: "read:current_user",
      });
      const response = await listEdgeDevices(accessToken, edgeId, 300);
      setData(response);
    } catch (e) {
      setError("An error occurred while fetching the data.");
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateEdge = async () => {
    const accessToken = await getAccessTokenSilently({
      audience: `https://${AUTH0_DOMAIN}/api/v2/`,
      scope: "read:current_user",
    });

    const updateData = {
      edge_id: item.GSI1SK,
    };

    if (item.edge_metadata.edge_name !== newName) {
      updateData.description = newName;
    }

    updateEdgeDevice(accessToken, updateData);

    getData();

    setEditingGSI1SK(null);
    setNewName("");
  };
  return (
    <tr key={index}>
      <td>{item.GSI1SK}</td>
      <td>
        {item.GSI1SK === editingGSI1SK ? (
          <div>
            <input
              type="text"
              value={newName}
              maxLength={20}
              onChange={(e) => setNewName(e.target.value)}
            />
            <button onClick={handleUpdateEdge}>OK</button>
            <button onClick={() => setEditingGSI1SK(null)}>Cancel</button>
          </div>
        ) : (
          <div>
            <span>{item.edge_metadata.edge_name}</span>
          </div>
        )}
      </td>
      {/* Render other table cells */}
      <td>
        <button
          className="settings-button"
          onClick={() => openModalForRow(index)}
        >
          <SettingsIcon />
        </button>
        <Modal
          overlayClassName="modal-overlay"
          className="modal-content"
          isOpen={modalIsOpenArr[index]}
          onClose={() => closeModalForRow(index)}
        >
          <div className="close-icon" onClick={() => closeModalForRow(index)}>
            &times;
          </div>
          <EdgeSettings item={item} />
        </Modal>
      </td>
      <td>
        <button className="delete-button" onClick={handleDeleteClick}></button>
      </td>
    </tr>
  );
};

export default EdgeDevicesRow;
