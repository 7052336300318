import React, { useEffect, useState, useCallback, useMemo } from "react";
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { useAuth0 } from "@auth0/auth0-react";
import DatePickerComponent from "./DatePickerComponent";
import EdgeDeviceSelect from "./EdgeDeviceSelect";
import MetricsTable from "./MetricsTable";
import MessageTable from "./MessageTable";
import TimeOnLocation from "./TimeOnLocation";
import {
  listEdgeDevices,
  fetchItemsRange,
  fetchMessageRange,
} from "./myApiHelpers";
import RefreshIcon from "@mui/icons-material/Refresh";
import "./Metrics.css";

const DEFAULT_EDGE_ID = 119;

function useLocalStorage(key, initialValue) {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.warn(error);
      return initialValue;
    }
  });

  const setValue = (value) => {
    try {
      setStoredValue(value);
      window.localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.warn(error);
    }
  };

  return [storedValue, setValue];
}

function dateToUnixTimestamp(date) {
  return date ? Math.floor(date.getTime()) : 0;
}

const Assets = () => {
  const { user, getAccessTokenSilently } = useAuth0();
  const [edgeId, setEdgeId] = useLocalStorage("edgeId", DEFAULT_EDGE_ID);
  const [data, setData] = useState([]);
  const [messageData, setMessageData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [edgeData, setEdgeData] = useState([]);
  const [modalIsOpenArr, setModalIsOpenArr] = useState([]);

  const fetchData = useCallback(async () => {
    setLoading(true);
    setError(null);

    try {
      const accessToken = await getAccessTokenSilently({
        audience: `https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/`,
        scope: "read:current_user",
      });

      const edgeDevices = await listEdgeDevices(accessToken);
      setEdgeData(edgeDevices);

      const unixTimestamp = dateToUnixTimestamp(selectedDate);
      const unixEndTimestamp = dateToUnixTimestamp(selectedEndDate);

      const response = await fetchItemsRange(
        accessToken,
        edgeId,
        unixTimestamp,
        unixEndTimestamp
      );
      setData(response);

      const messageResponse = await fetchMessageRange(
        accessToken,
        edgeId,
        unixTimestamp,
        unixEndTimestamp
      );
      setMessageData(messageResponse);
    } catch (e) {
      setError("An error occurred while fetching the data.");
    } finally {
      setLoading(false);
    }
  }, [getAccessTokenSilently, edgeId, selectedDate, selectedEndDate]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    setModalIsOpenArr(Array(data.length).fill(false));
  }, [data.length]);

  const handleRefresh = () => {
    fetchData();
  };

  const processedData = useMemo(
    () =>
      data
        .filter((item) => item.temperature !== 0 && item.humidity !== 0)
        .reverse()
        .map((item) => ({
          ...item,
          edgeTimestamp: new Date(item.edgeTimestamp).toLocaleString(),
        })),
    [data]
  );

  const batteryData = useMemo(
    () =>
      data
        .filter(
          (item) => item.batteryStatus && item.batteryStatus.voltage !== 0
        )
        .reverse()
        .map((item) => ({
          edgeTimestamp: new Date(item.edgeTimestamp).toLocaleString(),
          "batteryStatus.voltage": item.batteryStatus?.voltage || 0,
        })),
    [data]
  );

  const { max: humTempMaxValue, min: humTempMinValue } = useMemo(() => {
    const temperatures = data.map((item) => item.temperature);
    const humidities = data.map((item) => item.humidity);
    return {
      max: Math.max(...temperatures, ...humidities),
      min: Math.min(...temperatures, ...humidities),
    };
  }, [data]);

  const openModalForRow = (index) => {
    setModalIsOpenArr((prevArr) => {
      const newState = [...prevArr];
      newState[index] = true;
      return newState;
    });
  };

  const closeModalForRow = (index) => {
    setModalIsOpenArr((prevArr) => {
      const newState = [...prevArr];
      newState[index] = false;
      return newState;
    });
  };

  return (
    <div className="App">
      <header className="Header">
        <h3>Edge Device Metrics</h3>
      </header>

      <EdgeDeviceSelect
        edgeId={edgeId}
        setEdgeId={setEdgeId}
        edgeData={edgeData}
      />

      <DatePickerComponent
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        selectedEndDate={selectedEndDate}
        setSelectedEndDate={setSelectedEndDate}
      />

      <button
        onClick={handleRefresh}
        style={{
          backgroundColor: "transparent",
          border: "none",
          padding: 0,
          cursor: "pointer",
        }}
      >
        <RefreshIcon sx={{ color: "white", fontSize: 25 }} />
      </button>

      {loading ? (
        <div>Loading...</div>
      ) : (
        <>
          <div>
            <TimeOnLocation
              data={data}
              edgeId={edgeId}
              startDate={dateToUnixTimestamp(selectedDate)}
              endDate={dateToUnixTimestamp(selectedEndDate)}
            />
          </div>
          <div className="graph-container">
            <h3>Temperature and Humidity</h3>
            <ResponsiveContainer width="100%" height="100%">
              <LineChart className="line-chart" data={processedData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="edgeTimestamp" />
                <YAxis domain={[humTempMinValue - 10, humTempMaxValue + 10]} />
                <Tooltip contentStyle={{ backgroundColor: "#505050" }} />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="temperature"
                  stroke="#FF69B4"
                  name="Temperature (C°)"
                  dot={false}
                />
                <Line
                  type="monotone"
                  dataKey="humidity"
                  stroke="#82ca9d"
                  name="Humidty (%)"
                  dot={false}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
          <div className="graph-container">
            <h3>Battery Level</h3>
            <ResponsiveContainer width="100%" height="100%">
              <LineChart className="line-chart" data={batteryData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="edgeTimestamp" />
                <YAxis dataKey="batteryStatus.voltage" />
                <Tooltip contentStyle={{ backgroundColor: "#505050" }} />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="batteryStatus.voltage"
                  stroke="#FF69B4"
                  dot={false}
                  name="Battery Level (%)"
                />
              </LineChart>
            </ResponsiveContainer>
          </div>

          <h2>Timeseries</h2>
          <MetricsTable
            data={data}
            modalIsOpenArr={modalIsOpenArr}
            openModalForRow={openModalForRow}
            closeModalForRow={closeModalForRow}
          />
          <h2>Messages</h2>
          <MessageTable data={data} messageData={messageData} />
        </>
      )}
    </div>
  );
};

export default Assets;
