import React from "react";

const MessageTable = ({ data, messageData }) => {
  return (
    <div className="table-container">
      <table>
        <thead>
          <tr>
            <th>Edge ID</th>
            <th>Processed Timestamp</th>
            <th>Message</th>
          </tr>
        </thead>
        <tbody>
          {messageData.messages?.length > 0 ? (
            messageData.messages.map((item, index) => (
              <tr key={index}>
                <td>{item.GSI1PK}</td>
                <td>
                  {item.GSI1SK && !isNaN(item.GSI1SK)
                    ? new Date(parseInt(item.GSI1SK)).toLocaleString()
                    : "Invalid Timestamp"}
                </td>

                <td>{JSON.stringify(item.payload)}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="10">No messages available</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default MessageTable;
