import { useAuth0 } from "@auth0/auth0-react";

const API_URL = process.env.REACT_APP_API_URL;
const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN;

const fetchData = async (getAccessTokenSilently, edge_id) => {
  const API_ENDPOINT = `${API_URL}/v2/timeseries/?edge_id=${edge_id}&days_ago=3&&valid_positions_only=True`;
  try {
    const accessToken = await getAccessTokenSilently({
      audience: `https://${AUTH0_DOMAIN}/api/v2/`,
      scope: "read:current_user",
    });

    const response = await fetch(API_ENDPOINT, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const actualData = await response.json();
    localStorage.setItem("data", JSON.stringify(actualData));
    return actualData;
  } catch (err) {
    throw new Error(err.message);
  }
};

const fetchEdgedevices = async (getAccessTokenSilently, edge_id) => {
  const API_ENDPOINT = `${API_URL}/v2/edge`;
  try {
    const accessToken = await getAccessTokenSilently({
      audience: `https://${AUTH0_DOMAIN}/api/v2/`,
      scope: "read:current_user",
    });

    const response = await fetch(API_ENDPOINT, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const actualData = await response.json();
    localStorage.setItem("edgeData", JSON.stringify(actualData));
    return actualData;
  } catch (err) {
    throw new Error(err.message);
  }
};

export { fetchData, fetchEdgedevices };
