import React from "react";
import Map, { Marker, Popup } from "react-map-gl";

const TOKEN = process.env.REACT_APP_MAPBOX_TOKEN;

export const MapComponent = ({ latitude, longitude }) => {
  console.log(latitude, longitude);
  const [showPopup, setShowPopup] = React.useState(false);
  return (
    <div style={{ backgroundColor: "black", height: "200px" }}>
      <h1>Map</h1>
      <Map
        style={{ position: "absolute", top: 0, bottom: 0, width: "100%" }}
        initialViewState={{
          latitude: parseFloat(latitude),
          longitude: parseFloat(longitude),
          zoom: 12,
          width: "100%",
          height: "100%",
        }}
        mapStyle="mapbox://styles/mapbox/satellite-streets-v12"
        mapboxAccessToken={TOKEN}
      >
        <Marker
          latitude={parseFloat(latitude)}
          longitude={parseFloat(longitude)}
          offsetLeft={-20}
          offsetTop={-10}
        >
          {/* Marker */}
          <div
            style={{
              width: "20px",
              height: "20px",
              borderRadius: "50%",
              backgroundColor: "red",
            }}
            onClick={() => setShowPopup(true)}
          />
        </Marker>
        {showPopup && (
          <Popup
            latitude={parseFloat(latitude)}
            longitude={parseFloat(longitude)}
            closeButton={true}
            closeOnClick={false}
            onClose={() => setShowPopup(false)}
            anchor="top"
            style={{
              backgroundColor: "rgba(255, 0, 0, 0.8)",
              color: "Red",
              padding: "8px",
              borderRadius: "4px",
            }}
          >
            {/* Popup Content */}
            <div>
              Latitude: {latitude}, Longitude: {longitude}
            </div>
          </Popup>
        )}
      </Map>
    </div>
  );
};
