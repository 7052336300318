import React, { useState, useEffect } from "react";
import "./Metrics.css";
import { useAuth0 } from "@auth0/auth0-react";
import { timeOnLocation } from "./myApiHelpers";

const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN;

function formatTime(seconds) {
  const days = Math.floor(seconds / 86400);
  const hours = Math.floor((seconds % 86400) / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  return `${days} days, ${hours} hours, ${minutes} minutes`;
}

function TimeOnLocation({ edgeId, startDate, endDate }) {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const { getAccessTokenSilently } = useAuth0();

  const getData = async () => {
    try {
      const accessToken = await getAccessTokenSilently({
        audience: `https://${AUTH0_DOMAIN}/api/v2/`,
        scope: "read:current_user",
      });

      var payload = {
        edgeId: parseInt(edgeId, 10),
        startDate: startDate,
        endDate: endDate,
        daysAgo: 1,
        clusterRadius: 10,
      };

      const response = await timeOnLocation(accessToken, payload);
      setData(response);
    } catch (e) {
      console.error("An error occurred while fetching the data:", e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [getAccessTokenSilently, edgeId, startDate, endDate]);

  if (loading) {
    return <div className="table-container">Loading...</div>;
  }

  return (
    <div className="table-container">
      <h3>Summary</h3>
      <table>
        <thead>
          <tr>
            <th>Cluster ID</th>
            <th>Location</th>
            <th>Lat</th>
            <th>Lon</th>
            <th>Total Time</th>
            <th>Start Timestamp</th>
            <th>End Timestamp</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(data.clusters).map(
            ([clusterId, clusterData], index) => (
              <tr key={index}>
                <td>{clusterId}</td>
                <td>{clusterData?.location?.label || "N/A"}</td>
                <td>{(clusterData?.averageLatitude ?? 0).toFixed(4)}</td>
                <td>{(clusterData?.averageLongitude ?? 0).toFixed(4)}</td>
                <td>{formatTime(clusterData?.totalTimeSeconds || 0)}</td>
                <td>
                  {new Date(
                    (clusterData?.startTimestamp || 0) * 1000
                  ).toLocaleString()}
                </td>
                <td>
                  {new Date(
                    (clusterData?.endTimestamp || 0) * 1000
                  ).toLocaleString()}
                </td>
              </tr>
            )
          )}
        </tbody>
      </table>
    </div>
  );
}

export default TimeOnLocation;
