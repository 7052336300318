import React from "react";

export default function Home() {
  return (
    <div>
      <h3>Configuration</h3>
      <body>
        <br />
        <p>Feature coming soon! Configuration Page.</p>
      </body>
    </div>
  );
}
