import React, { useState } from "react";
import "./EdgeSettingsForm.css";
import { useAuth0 } from "@auth0/auth0-react";
import { writeEdgeConfig, updateEdgeDevice } from "./myApiHelpers";

const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN;

function ConfigForm({
  config,
  onClose,
  edgeId,
  edgeName: initialEdgeName,
  edgeDescription: initialEdgeDescription,
}) {
  // Separate state for edgeName and edgeDescription
  const [name, setName] = useState(initialEdgeName);
  const [description, setDescription] = useState(initialEdgeDescription);

  // Local state for form inputs, initialized with the config props
  const [formData, setFormData] = useState({ ...config });

  // State to track if the edgeName or edgeDescription has been updated
  const [nameUpdated, setNameUpdated] = useState(false);
  const [descriptionUpdated, setDescriptionUpdated] = useState(false);

  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (name === "edgeName") {
      setName(value);
      setNameUpdated(value !== initialEdgeName); // Set nameUpdated to true if the new value is different from the initial
    } else if (name === "edgeDescription") {
      setDescription(value);
      setDescriptionUpdated(value !== initialEdgeDescription); // Set descriptionUpdated to true if the new value is different from the initial
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const accessToken = await getAccessTokenSilently({
        audience: `https://${AUTH0_DOMAIN}/api/v2/`,
        scope: "read:current_user",
      });

      // Only update if there are changes to the edgeName or edgeDescription
      if (nameUpdated || descriptionUpdated) {
        var params = {
          edge_id: edgeId,
          name: name,
          description: description,
        };
        console.log(params);
        await updateEdgeDevice(accessToken, params);
      }

      // Update the configuration if there are changes
      if (Object.keys(formData).length > 0) {
        await writeEdgeConfig(accessToken, edgeId, formData);
      }

      onClose(); // Closing the form after submission
    } catch (e) {
      setError("An error occurred while saving the data.");
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="modal-settings">
      <div className="modal-settings-content">
        <span className="close-button" onClick={onClose}>
          &times;
        </span>
        <form onSubmit={handleSubmit} className="config-form">
          <div className="form-field">
            <label htmlFor="edgeName">Name:</label>
            <input
              type="text"
              id="edgeName"
              name="edgeName"
              value={name}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-field">
            <label htmlFor="edgeDescription">Description:</label>
            <input
              type="text"
              id="edgeDescription"
              name="edgeDescription"
              value={description}
              onChange={handleInputChange}
            />
          </div>
          {Object.entries(formData).map(([key, value]) => (
            <div key={key} className="form-field">
              <label htmlFor={key}>{`Config ${
                key.charAt(0).toUpperCase() + key.slice(1)
              }`}</label>
              <input
                type="number"
                id={key}
                name={key}
                value={value}
                onChange={handleInputChange}
              />
            </div>
          ))}
          <div className="form-actions">
            <button type="submit" disabled={loading}>
              Save Changes
            </button>
            <button type="button" onClick={onClose} disabled={loading}>
              Cancel
            </button>
          </div>
        </form>
        {error && <div className="error">{error}</div>}
      </div>
    </div>
  );
}

export default ConfigForm;
