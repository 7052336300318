import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import styles from "./sidenav.module.css";
import { NavLink } from "react-router-dom";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import { navData } from "../lib/navData";
import { useAuth0 } from "@auth0/auth0-react";

export default function Sidenav() {
  const { logout } = useAuth0();
  const [open, setOpen] = useState(false);
  const [showMenu, setShowMenu] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const location = useLocation();

  const controlNavbar = () => {
    if (location.pathname === "/explore") {
      // If on /explore route, always show the menu
      setShowMenu(true);
    } else {
      if (window.scrollY > lastScrollY) {
        // Scrolling down
        setShowMenu(false);
      } else {
        // Scrolling up
        setShowMenu(true);
      }
    }
    // Update the last scroll position
    setLastScrollY(window.scrollY);
  };
  /*
  useEffect(() => {
    window.addEventListener("scroll", controlNavbar);
    return () => {
      window.removeEventListener("scroll", controlNavbar);
    };
  
  }, [lastScrollY]);
  */

  const toggleOpen = () => {
    setOpen(!open);
  };

  // Define a class name for the sidenav based on the 'open' and 'showMenu' state
  const sidenavClassName = showMenu
    ? open
      ? styles.sidenav
      : styles.sidenavClosed
    : styles.sidenavHidden;

  const contentWrapperClassName = open
    ? styles.contentOpen
    : styles.contentClosed;

  return (
    <div>
      <div className={sidenavClassName}>
        {navData.map((item) => (
          <NavLink key={item.id} className={styles.sideitem} to={item.link}>
            {item.icon}
            {open && <span className={styles.linkText}>{item.text}</span>}
          </NavLink>
        ))}

        {/*
        <button className={styles.menuBtn} onClick={toggleOpen}>
          {open ? (
            <>
              <KeyboardDoubleArrowLeftIcon />
              <span>Collapse</span>
            </>
          ) : (
            <KeyboardDoubleArrowRightIcon />
          )}
        </button>

        <button className={styles.logoutBtn} onClick={logout}>
          <PowerSettingsNewIcon />
          {open && <span>Logout</span>}
        </button>
        */}
      </div>
    </div>
  );
}
