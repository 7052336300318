import React from "react";
import DatePicker from "react-datepicker";
import DateRangeIcon from "@mui/icons-material/DateRange";
import "react-datepicker/dist/react-datepicker.css";
import "./datepicker.css";
import { ContentCopy } from "@mui/icons-material";

const DatePickerComponent = ({
  selectedDate,
  setSelectedDate,
  selectedEndDate,
  setSelectedEndDate,
}) => {
  const handleDateChange = (dates) => {
    const [startDate, endDate] = dates;
    setSelectedDate(startDate);
    setSelectedEndDate(endDate);
  };

  // Custom style for the date picker input
  const customInput = (
    <div className="datepicker-input">
      {!selectedDate && !selectedEndDate && (
        <p>
          Select Date Range: <DateRangeIcon />{" "}
        </p>
      )}
      {selectedDate && selectedEndDate && (
        <p className="date-display">{`Selected Date Range: ${selectedDate.toDateString()} - ${selectedEndDate.toDateString()}`}</p>
      )}
    </div>
  );

  // Style to horizontally center the date picker
  return (
    <div className="datepicker-wrapper">
      <DatePicker
        selected={selectedDate}
        id="dateRange"
        startDate={selectedDate}
        endDate={selectedEndDate}
        onChange={handleDateChange}
        className="date-picker"
        selectsRange={true}
        isClearable
        clearIcon={<ContentCopy />}
        customInput={customInput} // Use custom input to display date range
      />
    </div>
  );
};

export default DatePickerComponent;
